import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';

import { stringToJSON, removeProperties, delay } from '~helpers/common';
import * as REST from '~services/rest';
import { getUserPicture, base64toBlob } from '~helpers/images';

const defaultState = {
  isLoading: false,
  isLoaded: false,
  user: {},
};

/**
 * Changes flat user data received from backend into more structured one
 * @param {Object} fetched user
 * @return - An user object
 */
export const parseFetchedUser = (fetchedUser) => {
  if (!fetchedUser) return null;

  const operations = fetchedUser.worker_data
    ? {
        minimumRate: { currency: fetchedUser.worker_data.currency, amount: fetchedUser.worker_data.amount },
        address: fetchedUser.worker_data.location,
        maxTravelDistance: fetchedUser.worker_data.max_distance,
        timeframes: fetchedUser.worker_data.timeframes,
        languages: fetchedUser.worker_data.languages,
        blockers: fetchedUser.worker_data.blockers,
        personalNote: fetchedUser.worker_data.personal_note,
        published: fetchedUser.worker_data.published,
        is_hidden: fetchedUser.worker_data.is_hidden,
      }
    : null;

  const wish = fetchedUser.customer_data
    ? {
        maximumRate: { currency: fetchedUser.customer_data.currency, amount: fetchedUser.customer_data.amount },
        address: fetchedUser.customer_data.location,
        duration: fetchedUser.customer_data.duration,
        interval: fetchedUser.customer_data.interval,
        timeframes: fetchedUser.customer_data.timeframes,
        languages: fetchedUser.customer_data.languages,
        attributes: fetchedUser.customer_data.blockers,
        personalNote: fetchedUser.customer_data.personal_note,
        published: fetchedUser.customer_data.published,
        is_hidden: fetchedUser.customer_data.is_hidden,
      }
    : null;

  const subscriptions = {
    userAlert: fetchedUser.customer_data ? fetchedUser.customer_data.user_alert : fetchedUser.worker_data.user_alert,
    personalMessage: fetchedUser.customer_data
      ? fetchedUser.customer_data.personal_message
      : fetchedUser.worker_data.personal_message,
  };

  return {
    personId: fetchedUser.person.id,
    email: fetchedUser.person.email,
    accountType: fetchedUser.customer_data ? 'client' : 'worker',
    firstName: fetchedUser.person.first_name,
    profilePicture: stringToJSON(fetchedUser?.person?.profile_picture),
    score: {
      score: fetchedUser.person.score,
      votes: fetchedUser.person.votes,
    },
    lastLogin: fetchedUser.person.last_login,
    isOnline: fetchedUser.person.is_online,
    verification: fetchedUser.person.verification,
    phoneNumber: fetchedUser.person.phone_no,
    isSuspended: fetchedUser.person.is_suspended,
    subscriptions,
    operations,
    wish,
  };
};

export const parseFetchedWorker = (fetchedWorker) => {
  if (!fetchedWorker) {
    return null;
  }
  return {
    personId: fetchedWorker.id,
    firstName: fetchedWorker.first_name,
    email: fetchedWorker.email,
    profilePicture: stringToJSON(fetchedWorker.profile_picture),
    score: fetchedWorker.score,
    price: fetchedWorker.price,
    published: fetchedWorker.published,
    location: {
      ...fetchedWorker.location,
      locationId: fetchedWorker?.location?.place_id,
      description: fetchedWorker?.location?.address,
    },
    experience: fetchedWorker.experience,
    lastLogin: fetchedWorker.last_login,
    isOnline: fetchedWorker.is_online,
    registerDate: fetchedWorker?.registered_date,
    personalNote: fetchedWorker.personal_note,
    verification: [
      {
        type: 'email',
        isVerified: (fetchedWorker.verification.find((item) => item.type === 'EMAIL') || {}).is_verified || false,
      },
      {
        type: 'sms',
        isVerified: (fetchedWorker.verification.find((item) => item.type === 'SMS') || {}).is_verified || false,
      },
    ],
    accountType: 'worker',
  };
};

export const parseFetchedCustomer = (fetchedCustomer) => {
  if (!fetchedCustomer) {
    return null;
  }
  return {
    personId: fetchedCustomer.id,
    firstName: fetchedCustomer.first_name,
    email: fetchedCustomer.email,
    profilePicture: stringToJSON(fetchedCustomer.profile_picture),
    score: fetchedCustomer.score,
    price: fetchedCustomer.price,
    published: fetchedCustomer.published,
    location: {
      ...fetchedCustomer.location,
      locationId: fetchedCustomer?.location?.place_id,
      description: fetchedCustomer?.location?.address,
    },
    experience: fetchedCustomer.experience,
    lastLogin: fetchedCustomer.last_login,
    isOnline: fetchedCustomer.is_online,
    registerDate: fetchedCustomer.registered_date,
    personalNote: fetchedCustomer.personal_note,
    verification: [
      {
        type: 'email',
        isVerified: (fetchedCustomer.verification.find((item) => item.type === 'EMAIL') || {}).is_verified || false,
      },
      {
        type: 'sms',
        isVerified: (fetchedCustomer.verification.find((item) => item.type === 'SMS') || {}).is_verified || false,
      },
    ],
    accountType: 'client',
    days: fetchedCustomer.days,
    interval: fetchedCustomer.interval,
    duration: fetchedCustomer.duration,
  };
};

export const parseFetchedSubscriptionPayments = (fetchedSubPayments) => {
  if (!fetchedSubPayments || !fetchedSubPayments.data) {
    return null;
  }

  return fetchedSubPayments.data.map((payment) => ({
    paymentId: payment.id,
    currentStatus: payment.current_status,
    placeId: payment.place_id,
    isSubscription: payment.is_subscription,
    nextRecurringDate: payment.next_recurring_date, // Assuming this is a timestamp; format as needed
    subscriptionId: payment.subscription_id,
    isDeleted:payment.is_deleted,
    location: payment.location ? {
      placeId: payment.location.place_id,
      address: payment.location.address,
      city: payment.location.city,
      country: payment.location.country,
      latitude: payment.location.latitude,
      longitude: payment.location.longitude,
      slug: payment.location.slug,
    } : null,
    subscriptions: payment.subscriptions ? payment.subscriptions.map((subscription) => ({
      subscriptionId: subscription.id,
      subscriptionStatus: subscription.subscription_status,
      totalConnects: subscription.total_connects,
      remainingConnects: subscription.remaining_connects,
      invoiceId: subscription.invoice_id,
      currentPeriodStart: subscription.current_period_start, // Assuming these are timestamps; format as needed
      currentPeriodEnd: subscription.current_period_end,
      createdAt: subscription.created_at,
      updatedAt: subscription.updated_at,
      cancelAtPeriodEnd: subscription.cancel_at_period_end,
    })) : [],
  }));
};


export const UserContext = React.createContext(defaultState);

const UserProvider = ({ children }) => {
  const { user: authUser, isAuthenticated, isLoading: isLoadingAuth0, getAccessTokenSilently } = useAuth0();
  const s3Url = useSelector((state) => state.domainConfig.s3Url);

  const [isLoading, setIsLoading] = useState(defaultState.isLoading);
  const [isLoaded, setIsLoaded] = useState(defaultState.isLoaded);
  const [user, setUser] = useState(defaultState.user);

  const getOwnData = async (personId = user?.personId) => {
    if (!personId) {
      return;
    }

    setIsLoading(true);

    try {
      const token = await getAccessTokenSilently();
      const fetchedUser = await REST.get({
        name: `persons/${personId}`,
        shouldThrowError: true,
        mockFileName: 'persons/get',
        token,
      });
      if (
        fetchedUser &&
        fetchedUser.data &&
        fetchedUser.data[0] &&
        fetchedUser.data[0].person &&
        fetchedUser.data[0].person.id
      ) {
        const newUser = parseFetchedUser(fetchedUser.data[0]);
        setUser(newUser);
        return newUser;
      }
    } catch (err) {
      // console.log('Error while fetching user from backend', err);
      setUser(defaultState.user);
      return null;
    } finally {
      setIsLoaded(true);
      setIsLoading(false);
    }
  };

  const getSubscriptionPayments = async (personId) => {
    if (!personId) {
      return;
    }
    const token = await getAccessTokenSilently();
    const fetchedSubPayments = await REST.get({
      name: `payments?get-subscriptions=true&customer-id=${personId}`,
      mockFileName: 'payments/get-subscription-payments',
      shouldThrowError: true,
      token,
    });
    if (fetchedSubPayments && fetchedSubPayments.data) {
      const newSubPayments = parseFetchedSubscriptionPayments(fetchedSubPayments);
      return newSubPayments;
    }
  };

  const resumeSubscription = async (subscriptionId) => {
    if (!subscriptionId) {
      return;
    }
    const token = await getAccessTokenSilently();
    return REST.post({
      name: `payments`,
      mockFileName: 'payments/resume-subscription',
      params: {
        person_id: user.personId,
        method:"resume_subscription",
        subscription_id: subscriptionId,
      },
      shouldThrowError: true,
      token,
    });
  };

  const pauseSubscription = async (subscriptionId) => {
    if (!subscriptionId) {
      return;
    }
    const token = await getAccessTokenSilently();
    return REST.post({
      name: `payments`,
      mockFileName: 'payments/pause-subscription',
      params: {
        person_id: user.personId,
        method:"pause_subscription",
        subscription_id: subscriptionId,
      },
      shouldThrowError: true,
      token,
    });
  };

  const deleteSubscription = async (subscriptionId) => {
    if (!subscriptionId) {
      return;
    }
    const token = await getAccessTokenSilently();
    return REST.post({
      name: `payments`,
      mockFileName: 'payments/pause-subscription',
      params: {
        person_id: user.personId,
        method:"delete_subscription",
        subscription_id: subscriptionId,
      },
      shouldThrowError: true,
      token,
    });
  };

  const getWorker = async (personId) => {
    if (!personId) {
      return;
    }

    try {
      const fetchedUser = await REST.get({
        name: `workers/${personId}`,
        mockFileName: 'workers/get-worker',
        shouldThrowError: true,
      });
      if (fetchedUser && fetchedUser.data && fetchedUser.data[0] && fetchedUser.data[0].id) {
        const newUser = parseFetchedWorker(fetchedUser.data[0]);
        return newUser;
      }
    } catch (err) {
      console.log('Error while fetching user from backend', err);
      throw err;
    }
  };

  const getCustomer = async (personId) => {
    if (!personId) {
      return;
    }

    const fetchedUser = await REST.get({
      name: `customers/${personId}`,
      mockFileName: 'customers/get-customer',
      shouldThrowError: true,
    });
    if (fetchedUser && fetchedUser.data && fetchedUser.data[0] && fetchedUser.data[0].id) {
      const newUser = parseFetchedCustomer(fetchedUser.data[0]);
      return newUser;
    }
  };

  const processRegistrationWithBackend = async (uuid, email = authUser?.email) => {
    if (!email) {
      return;
    }

    const token = await getAccessTokenSilently();
    try {
      const fetchedUser = await REST.get({
        name: `persons?email=${email}`,
        shouldThrowError: true,
        mockFileName: 'persons/get',
        token,
      });

      // If email found that means its reregistration
      // Backend will update auth0 and return users true uuid

      // We need to force update token
      await getAccessTokenSilently({ cacheMode: 'off' });
      return fetchedUser.data[0];
    } catch (err) {
      // In case we dont find the user backend returns 404
      // Thats fine for us, it means its totally new user
      return uuid;
    }
  };

  // Try to fetch user on session recovery
  useEffect(async () => {
    if (isLoadingAuth0 || !isAuthenticated) {
      return;
    }

    setIsLoading(true);
    const uuid =
      authUser['https://sr2.ca/claims/user_metadata'].is_registration_processed === false
        ? await processRegistrationWithBackend(authUser['https://sr2.ca/claims/user_metadata'].uuid, authUser.email)
        : authUser['https://sr2.ca/claims/user_metadata'].uuid;

    await getOwnData(uuid);
  }, [isLoadingAuth0, isAuthenticated]);

  const clear = () => {
    setIsLoading(defaultState.isLoading);
    setIsLoaded(defaultState.isLoaded);
    setUser(defaultState.user);
  };

  const createCustomer = async ({
    personId,
    email,
    firstName,
    profilePicture,
    emailVerified = false,
    phoneVerified = false,
    wish = {},
  }) => {
    const person = {
      id: personId,
      email,
      first_name: firstName,
      profile_picture: profilePicture,
      verification: [
        {
          type: 'SMS',
          is_verified: phoneVerified,
        },
        {
          type: 'EMAIL',
          is_verified: emailVerified,
        },
      ],
    };

    const customerData =
      wish && wish.address
        ? {
            currency: wish.maximumRate?.currency,
            amount: wish.maximumRate?.amount,
            personal_note: wish.personalNote,
            location: removeProperties(wish.address, 'locationId', 'description'),
            blockers: wish.attributes,
            languages: wish.languages,
            timeframes: wish.timeframes,
            duration: wish.duration,
            interval: wish.interval,
            published: wish.published,
            is_hidden: false,
          }
        : {};
    const token = await getAccessTokenSilently();
    return REST.post({
      name: `customers`,
      mockFileName: 'customers/post-customer',
      params: {
        person,
        customer_data: customerData,
      },
      shouldThrowError: true,
      token,
    });
  };

  const createWorker = async ({
    personId,
    email,
    firstName,
    profilePicture,
    emailVerified,
    phoneVerified,
    operations = {},
  }) => {
    const person = {
      id: personId,
      email,
      first_name: firstName,
      profile_picture: profilePicture,
      verification: [
        {
          type: 'SMS',
          is_verified: phoneVerified,
        },
        {
          type: 'EMAIL',
          is_verified: emailVerified,
        },
      ],
    };

    const workerData =
      operations && operations.address
        ? {
            currency: operations?.minimumRate?.currency,
            amount: operations?.minimumRate?.amount,
            personal_note: operations?.personalNote,
            timeframes: operations?.timeframes,
            location: removeProperties(operations.address, 'locationId', 'description'),
            max_distance: operations?.maxTravelDistance,
            blockers: operations?.blockers,
            languages: operations?.languages,
            published: true,
            is_hidden: false,
          }
        : {};

    const token = await getAccessTokenSilently();
    return REST.post({
      name: `workers`,
      mockFileName: 'workers/post-worker',
      params: {
        person,
        worker_data: workerData,
      },
      shouldThrowError: true,
      token,
    });
  };

  const updateCustomer = async ({ personId, firstName, email, phoneNumber, profilePicture, wish = {} }) => {
    const token = await getAccessTokenSilently();
    const response = await REST.put({
      name: `customers`,
      mockFileName: 'customers/put-customer',
      params: {
        person: {
          id: personId,
          email,
          phone_no: phoneNumber,
          first_name: firstName,
          profile_picture: typeof profilePicture === 'object' ? JSON.stringify(profilePicture) : profilePicture,
        },
        customer_data: {
          currency: wish?.maximumRate?.currency,
          amount: wish?.maximumRate?.amount,
          personal_note: wish?.personalNote,
          location: removeProperties(wish.address, 'locationId', 'description'),
          blockers: wish?.attributes,
          languages: wish?.languages,
          timeframes: wish.timeframes,
          duration: wish?.duration,
          interval: wish?.interval,
          published: wish?.published,
          is_hidden: wish?.is_hidden,
        },
      },
      shouldThrowError: true,
      token,
    });
    const newUser = {
      ...user,
      email,
      personId,
      firstName,
      phoneNumber,
      profilePicture:
        typeof profilePicture === 'object' ? profilePicture : !profilePicture ? null : JSON.parse(profilePicture),
      wish,
    };
    setUser(newUser);
    return response;
  };

  const updateWorker = async ({ personId, phoneNumber, email, firstName, profilePicture, operations = {} }) => {
    const token = await getAccessTokenSilently();
    const response = await REST.put({
      name: `workers`,
      mockFileName: 'workers/put-worker',
      params: {
        person: {
          id: personId,
          first_name: firstName,
          email,
          profile_picture: typeof profilePicture === 'object' ? JSON.stringify(profilePicture) : profilePicture,
          phone_no: phoneNumber,
        },
        worker_data: {
          currency: operations?.minimumRate?.currency,
          amount: operations?.minimumRate?.amount,
          personal_note: operations?.personalNote,
          timeframes: operations?.timeframes,
          location: removeProperties(operations?.address, 'locationId', 'description'),
          max_distance: operations?.maxTravelDistance,
          blockers: operations?.blockers,
          languages: operations?.languages,
          published: operations?.published,
          is_hidden: operations?.is_hidden,
        },
      },
      shouldThrowError: true,
      token,
    });

    const newUser = {
      ...user,
      personId,
      email,
      firstName,
      phoneNumber,
      profilePicture:
        typeof profilePicture === 'object' ? profilePicture : !profilePicture ? null : JSON.parse(profilePicture),
      operations,
    };
    setUser(newUser);
    return response;
  };

  const updateWorkerParam = async (workerId, params, updatedUser) => {
    const token = await getAccessTokenSilently();
    const response = await REST.patch({
      name: `workers/${workerId}`,
      mockFileName: 'workers/put-worker',
      params: { ...params },
      shouldThrowError: true,
      token,
    });

    const newUser = {
      ...user,
      ...updatedUser,
    };
    setUser(newUser);
    return response;
  };

  const updateCustomerParam = async (customerId, params, updatedUser) => {
    const token = await getAccessTokenSilently();
    const response = await REST.patch({
      name: `customers/${customerId}`,
      mockFileName: 'customers/put-customer',
      params: { ...params },
      shouldThrowError: true,
      token,
    });

    const newUser = {
      ...user,
      ...updatedUser,
    };
    setUser(newUser);
    return response;
  };

  const deleteUser = async (personId) => {
    const token = await getAccessTokenSilently();
    return REST.del({
      name: `persons/${personId}`,
      shouldThrowError: true,
      token,
    });
  };

  const postPicture = async (image, personId, size) => {
    const headers = {
      'Content-Type': 'image/webp',
    };

    const token = await getAccessTokenSilently();
    return REST.post({
      name: `persons/${personId}/pictures?type=${size}`,
      headers,
      data: image,
      token,
      fallback: true,
    });
  };

  const deleteUserPicture = async (personId) => {
    const token = await getAccessTokenSilently();
    return REST.del({
      name: `persons/${personId}/pictures`,
      shouldThrowError: true,
      token,
    });
  };

  const uploadUserPicture = async (picture, personId) => {
    const large = await base64toBlob(picture);
    await postPicture(large, personId, 'large');
    delay(2000); // we need to wait to make sure thumbnail is also invalidated
    return getUserPicture(s3Url, personId);
  };

  const state = {
    isLoading,
    isLoaded,
    user,
    getOwnData,
    getWorker,
    getCustomer,
    createCustomer,
    createWorker,
    updateCustomer,
    updateWorker,
    deleteUser,
    updateWorkerParam,
    updateCustomerParam,
    clear,
    uploadUserPicture,
    deleteUserPicture,
    getSubscriptionPayments,
    pauseSubscription,
    resumeSubscription,
    deleteSubscription,
  };

  return <UserContext.Provider value={state}>{children}</UserContext.Provider>;
};

export default UserProvider;
