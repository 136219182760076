import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { getRelativeTime } from '~helpers/timeframes';
import { capitalize } from '~helpers/common';

import './style.scss';

const LastOnline = ({ lastOnline, isOnline, isPublished = true }) => {
  const intl = useIntl();

  const [lastOnlineFormatted, setLastOnlineFormatted] = useState(null);

  useEffect(() => {
    if (isOnline) {
      setLastOnlineFormatted('now');
      return;
    }
    if (!lastOnline) {
      setLastOnlineFormatted(null);
      return;
    }

    const lastOnlineV = getRelativeTime(lastOnline, Math.floor(Date.now() / 1000));
    if (
      lastOnlineV.value >= 0 ||
      lastOnlineV.unit === 'second' ||
      (lastOnlineV.unit === 'minute' && lastOnlineV.value >= -5)
    ) {
      setLastOnlineFormatted('now');
    }
    setLastOnlineFormatted(lastOnlineV);
  }, [lastOnline]);

  if (!isPublished) {
    return <div className="c-last-online">{intl.formatMessage({ id: 'components.last-online.not-active-recently', defaultMessage: 'Not active recently' })}</div>;
  }

  return (
    <div className="c-last-online">
      {!lastOnlineFormatted ? (
        <>-</>
      ) : lastOnlineFormatted === 'now' ? (
        <>
          <i className="c-last-online__online" />{' '}
          {intl.formatMessage({ id: 'components.last-online.online', defaultMessage: 'Online' })}
        </>
      ) : (
        capitalize(
          intl.formatRelativeTime(lastOnlineFormatted.value, lastOnlineFormatted.unit, {
            numeric: 'always',
            style: 'long',
          })
        )
      )}
    </div>
  );
};

export default LastOnline;
